import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SERVER = process.env.REACT_APP_BACK_HOGARNIMMO_SERVER;

export async function callEndpoint (type, path, headers, body, token) {
    headers = headers || {};
    if (headers['Authorization'] === undefined) {
        headers['Authorization'] = `Basic ${token}`;
    }
    if (type === 'GET') {
        return await axios.get(SERVER + path, {headers: headers});
    } else if (type === 'POST') {
        headers['Content-Type'] = 'application/json';
        return await axios.post(SERVER + path, body, {headers: headers});
    } else if (type === 'DELETE') {
        return await axios.delete(SERVER + path, {headers: headers});
    }
}

export function getEndpoint(entity){
    if (entity === 'Beneficiarios' || entity === 'Sujetos de derechos'){
        return 'beneficiarios';
    } else if (entity === 'Intervenciones'){
        return 'formularios/intervencion';
    } else if (entity === 'Parte Diarios'){
        return 'formularios/partediario';
    } else if (entity === 'Usuarios'){
        return 'usuarios';
    } else if (entity === 'Actividades'){
        return 'data/actividades';
    } else if (entity === 'Derechos'){
        return 'data/derechos';
    } else if (entity === 'Login'){
        return 'login';
    } else {
        return ''
    }
}

export async function exportToPDF(containerId, title, excludeIds = []) {
    excludeIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
            element.style.display = 'none';
        }
    });

    const input = document.getElementById(containerId);
    input.style.overflow = 'visible';
    const canvas = await html2canvas(input, {
        scale: 1,
        useCORS: true,
        scrollY: -window.scrollY,
        windowHeight: document.documentElement.offsetHeight
    });

    excludeIds.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
            element.style.display = '';
        }
    });

    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
        orientation: 'landscape',
    });
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    // Ajustar la imagen al tamaño de la página del PDF si es demasiado larga
    const pageHeight = pdf.internal.pageSize.getHeight();
    let heightLeft = pdfHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - pdfHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;
    }

    pdf.save(title + '.pdf');
}
