import React, {useState, useEffect, useContext} from 'react';
import {
    DataGrid,
    gridClasses,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridActionsCellItem
} from '@mui/x-data-grid';
import {Box, Card, CardContent, CardHeader, Snackbar} from '@mui/material';
import {GlobalContext} from "./Context";
import {alpha, styled} from "@mui/material/styles";
import {callEndpoint, getEndpoint} from "./Utils";
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from "@mui/material/Tooltip";

function ListPage(props) {
    const { user, token } = useContext(GlobalContext);

    const [rows, setRows] = useState([]);
    const [rowsSchema, setRowsSchema] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const dictionary = { "beneficiarios": "Sujetos de derechos", "turnoCompleto": "Turno Completo", "audIns": "Creado", "createdBy": "Creado Por", "name":"Nombre", "audCreatedBy":"Creado Por" };

    function getJsonSchema(json) {
        const schema = {};
        for (const key in json) {
            schema[key] = typeof json[key];
        }
        return schema;
    }

    useEffect(() => {
        function getSubset(obj, keys) {
            return Object.fromEntries(
                Object.entries(obj).filter(([key]) => keys.includes(key))
            );
        }

        async function fetchData() {
            try {
                const response = await callEndpoint('GET', getEndpoint(props.title), null, null, token);

                if (response.status === 200) {
                    let responseSchema = {};
                    responseSchema = getJsonSchema(response.data[0]);
                    if (props.schema){
                        setRowsSchema(getSubset(responseSchema, props.schema));
                    }else {
                        setRowsSchema(responseSchema);
                    }
                    setRows(response.data);
                }
            } catch (error) {
                console.error('Error al obtener los ', props.title, error);
            }
        }

        fetchData();
    }, [user, props, token]);

    function ConvertValueToString(value) {
        if (value === null || value === undefined){
            return '';
        }
        // Is an array of objects
        if (Array.isArray(value) && value.length > 0){
            return value.reduce((acc, value) => {return acc + value.name + ', ';}, '').slice(0, -2);
        // Is an object
        }else if (typeof value === 'object'){
            return `${value.name}`;
        }else if (typeof value === 'boolean'){
            return value ? 'Sí' : 'No';
        } else {
            return value;
        }

    }

    function RenderCell(params) {
        const value = ConvertValueToString(params.value);
        const shortDescription = value.length > 40 ? value.substring(0, 40) + '...' : value;
        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
          setOpen(true);
        };

        const handleClose = () => {
          setOpen(false);
        };

        return (
          <>
            <div onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
              {shortDescription}
            </div>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <div>{value}</div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
    }

    const handleDeleteClick = (id) => () => {
        callEndpoint('DELETE', getEndpoint(props.title) + '/' + id, null, null, token)
            .then(deleteResponse => {
                setRows((prevRows) => prevRows.filter((row) => row.id !== id));
                setSuccessMessage(props.title + ' borrada con éxito!');
                setOpenSnackbar(true);
            })
            .catch(error => {
                setSuccessMessage(props.title + ' no se ha podido borrar!');
                setOpenSnackbar(true);
                console.error('Error deleting', id, error);
            });
    };

    const ODD_OPACITY = 0.2;

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200],
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity,
                    ),
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            ODD_OPACITY + theme.palette.action.selectedOpacity,
                        ),
                    },
                },
            },
        },
    }));

    function getColumns(rowsSchema){
        let columns = Object.keys(rowsSchema).map((key) => {
            return {
                field: key,
                headerName: dictionary[key]? dictionary[key]: key.charAt(0).toUpperCase() + key.slice(1),
                maxWidth: 300,
                renderCell: (params) => RenderCell(params),
            };
        });
        if (user.roles.find((r) => r.name === 'admin')){
            columns.push({
                field: 'actions',
                type: 'actions',
                headerName: 'Acciones',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id }) => {
                    return [
                        <GridActionsCellItem
                        icon={<Tooltip title="Borrar">
                            <DeleteIcon />
                          </Tooltip>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        />,
                    ];
                },
            });
        }
        return columns;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box>
            <Card sx={{ maxHeight: `calc(100vh - 150px)`, overflow: 'auto' }}>
                <CardHeader title={props.title} />
                <CardContent sx={{ pt: 0 }}>

                    <StripedDataGrid
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        columns={getColumns(rowsSchema)}
                        rows={rows}
                        slots={{ toolbar: CustomToolbar }}
                        //editMode="row"
                        disableDensitySelector
                        //checkboxSelection
                        disableRowSelectionOnClick
                        autoHeight
                        autosizeOnMount
                        pageSizeOptions={[10, 20, 50, 80]}
                        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
                        pagination
                    />
                </CardContent>
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={successMessage}
            />
        </Box>
    )
}

export default ListPage;
